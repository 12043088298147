import React from 'react';
import './loader.css';


//ESTE ES EL BOOOOOORDE ////
let text = "<www.pepesainz.dev/>";
const IconLoader = () => (
  <div class="wrapper-demo">
  <div class="typing-demo">
    {text}
  </div>
</div>


);

export default IconLoader;
